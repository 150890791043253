<template>
	<div class="p404">
		<div>
			<img src="../assets/image/404.png"
			     srcset="../assets/image/404.png,../assets/image/404@2x.png 2x"
			     alt="">
		</div>
		<div class="p404-text">
			<div class="title">抱歉 您访问的页面走丢了～</div>
			<div class="content">
				<div>可能原因：</div>
				<div>1、<b>网址有错误</b>（请检查地址是否完整或多余）；</div>
				<div>2、<b>网址有失效</b>（页面已删除，活动已下线）；</div>
			</div>
			<div class="btn-container">
				<el-button @click="$router.push('/')">返回首页</el-button>
				<el-button type="primary" @click="$router.back()">返回上级</el-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.p404 {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	
	.el-button {
		width: 128px;
		
		&:first-of-type {
			margin-right: 40px;
		}
	}
}

.p404-text {
	margin-left: 164px;
	
	.title {
		font-size: 32px;
		font-weight: 400;
		margin-bottom: 24px;
	}
	
	.content {
		font-size: 14px;
		
		> div {
			margin-bottom: 8px;
		}
	}
	
	.btn-container {
		margin-top: 120px;
	}
}
</style>
